import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Icon } from 'src/components/atoms/Icon';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsHubspotForm');
  customData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {ctas.length > 0 && (
            <Grid>
              {ctas.map((cta, index) => {
                const ctaCustomData = JSON.parse(cta.customData);
                return (
                  <Column key={index}>
                    <Grid columns="max-content 1fr" verticalAlign="center">
                    <Column>
                      {ctaCustomData?.icon && <Icon icon={ctaCustomData?.icon} size="3x" circle />}
                    </Column>
                    <Column>
                      {!!cta.heading && (
                        <h3>
                          {!!cta.url && <a href={cta.url}>{cta.heading}</a>}
                          {!cta.url && cta.heading}
                        </h3>
                      )}
                      <div
                        dangerouslySetInnerHTML={{__html: cta.text}}
                      />
                    </Column>
                    </Grid>
                  </Column>
                )
              })}
            </Grid>
        )}
      </Section>
    </MDXProvider>
  );
}
